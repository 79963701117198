import TriggerBlogItem from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-blog-item'
import TriggerCampaignItem from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-campaign-item'
import TriggerCommunityItem from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-community-item'
import TriggerCourseBundleItem from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-course-bundle-item'
import TriggerCourseItem from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-course-item'
import TriggerEmailItem from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-email-item'
import TriggerEmailLinkItem from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-email-link-item'
import TriggerFunnelItem from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-funnel-item'
import TriggerLectureItem from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-lecture-item'
import TriggerModuleItem from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-module-item'
import TriggerTagItem from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-tag-item'
import TriggerWebinarItem from 'modules/automation-rules/triggers/components/edit-triggers/components/trigger-webinar-item'
import { EditTriggerItemInterface } from 'modules/automation-rules/triggers/components/edit-triggers/types/edit-trigger-item-interface'
import {
  AutomationRuleTriggersDataEnum,
  AutomationRuleTriggersEnum,
} from 'modules/automation-rules/triggers/enums/trigger-enum'
import {
  TriggerEditType,
  TriggersType,
} from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import {
  AutomationRuleErrorInterface,
  AutomationRuleViewInterface,
} from 'modules/automation-rules/types/automation-rule-interface'
import React from 'react'

export interface EditTriggersProps {
  triggers: TriggerEditType[]
  setRules: React.Dispatch<React.SetStateAction<AutomationRuleViewInterface>>
  errors: AutomationRuleErrorInterface
  isStatic?: boolean
  hideTriggers?: number[]
}

const EditTriggers = ({
  triggers,
  setRules,
  errors,
  isStatic,
  hideTriggers = [],
}: EditTriggersProps) => {
  const onDelete = (index: number) => {
    setRules(prev => {
      return { ...prev, triggers: triggers.filter((_, idx) => idx !== index) }
    })
  }

  return (
    <>
      {triggers.map((trigger, idx) => {
        if (hideTriggers.includes(trigger.id)) return null
        const baseProps: Omit<EditTriggerItemInterface<TriggersType>, 'trigger'> = {
          triggers,
          idx,
          errors,
          setRules,
          onDelete,
          isStatic,
        }
        switch (trigger.type) {
          case AutomationRuleTriggersEnum.tag_removed:
          case AutomationRuleTriggersEnum.tag_added: {
            return <TriggerTagItem key={idx} {...baseProps} trigger={trigger} />
          }
          case AutomationRuleTriggersEnum.form_subscribed: {
            return (
              <TriggerFunnelItem
                key={idx}
                {...baseProps}
                trigger={trigger}
                funnelData={'funnelsFormSubscribeData'}
                funnelStepData={'funnelStepsFormSubscribeData'}
                funnelDataStr={AutomationRuleTriggersDataEnum.form_subscribed}
                funnelStepDataStr={AutomationRuleTriggersDataEnum.form_subscribed_funnel_step}
              />
            )
          }
          case AutomationRuleTriggersEnum.page_visited: {
            return (
              <TriggerFunnelItem
                key={idx}
                {...baseProps}
                trigger={trigger}
                funnelData={'funnelsPageVisitedData'}
                funnelStepData={'funnelStepsPageVisitedData'}
                funnelDataStr={AutomationRuleTriggersDataEnum.page_visited}
                funnelStepDataStr={AutomationRuleTriggersDataEnum.page_visited_funnel_step}
              />
            )
          }
          case AutomationRuleTriggersEnum.new_sale: {
            return (
              <TriggerFunnelItem
                key={idx}
                {...baseProps}
                trigger={trigger}
                funnelData={'funnelsNewSaleData'}
                funnelStepData={'funnelStepsNewSaleData'}
                funnelDataStr={AutomationRuleTriggersDataEnum.new_sale}
                funnelStepDataStr={AutomationRuleTriggersDataEnum.new_sale_funnel_step}
              />
            )
          }
          case AutomationRuleTriggersEnum.sale_canceled: {
            return (
              <TriggerFunnelItem
                key={idx}
                {...baseProps}
                trigger={trigger}
                funnelData={'funnelsSaleCanceledData'}
                funnelStepData={'funnelStepsSaleCanceledData'}
                funnelDataStr={AutomationRuleTriggersDataEnum.sale_canceled}
                funnelStepDataStr={AutomationRuleTriggersDataEnum.sale_canceled_funnel_step}
              />
            )
          }
          case AutomationRuleTriggersEnum.blog_page_form_subscribed: {
            return <TriggerBlogItem key={idx} {...baseProps} trigger={trigger} />
          }
          case AutomationRuleTriggersEnum.registered_to_webinar: {
            return <TriggerWebinarItem key={idx} {...baseProps} trigger={trigger} />
          }
          case AutomationRuleTriggersEnum.campaign_completed: {
            return <TriggerCampaignItem key={idx} {...baseProps} trigger={trigger} />
          }
          case AutomationRuleTriggersEnum.enrolled_in_course: {
            return <TriggerCourseItem key={idx} {...baseProps} trigger={trigger} />
          }
          case AutomationRuleTriggersEnum.course_completed: {
            return <TriggerCourseItem key={idx} {...baseProps} trigger={trigger} />
          }
          case AutomationRuleTriggersEnum.module_completed: {
            return <TriggerModuleItem key={idx} {...baseProps} trigger={trigger} />
          }
          case AutomationRuleTriggersEnum.lecture_completed: {
            return <TriggerLectureItem key={idx} {...baseProps} trigger={trigger} />
          }
          case AutomationRuleTriggersEnum.enrolled_in_course_bundle: {
            return <TriggerCourseBundleItem key={idx} {...baseProps} trigger={trigger} />
          }
          case AutomationRuleTriggersEnum.enrolled_in_community: {
            return <TriggerCommunityItem key={idx} {...baseProps} trigger={trigger} />
          }
          case AutomationRuleTriggersEnum.email_link_clicked: {
            return <TriggerEmailLinkItem key={idx} {...baseProps} trigger={trigger} />
          }
          case AutomationRuleTriggersEnum.email_opened: {
            return <TriggerEmailItem key={idx} {...baseProps} trigger={trigger} />
          }

          case AutomationRuleTriggersEnum.subscription_payment_failed: {
            return (
              <TriggerFunnelItem
                key={idx}
                {...baseProps}
                trigger={trigger}
                funnelData={'funnelsSubscriptionPaymentFailedData'}
                funnelStepData={'funnelStepsSubscriptionPaymentFailedData'}
                funnelDataStr={AutomationRuleTriggersDataEnum.subscription_payment_failed}
                funnelStepDataStr={
                  AutomationRuleTriggersDataEnum.subscription_payment_failed_funnel_step
                }
              />
            )
          }

          default: {
            return null
          }
        }
      })}
    </>
  )
}

export default EditTriggers
