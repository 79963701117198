import { AutomationRuleActionsEnum } from 'modules/automation-rules/actions/enums/action-enum'
import {
  BlogEntityEnum,
  BlogPageEnum,
} from 'modules/automation-rules/enums/automation-rule-enum'
import { AutomationRuleNamesEnum } from 'modules/automation-rules/enums/automation-rule-filter-enum'
import { AutomationRuleTriggersEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { AutomationRuleViewInterface } from 'modules/automation-rules/types/automation-rule-interface'

export const getRuleData = (rules: AutomationRuleViewInterface) => {
  let data = ''
  const triggersKey = 'automationTriggers'
  const actionsKey = 'automationActions'

  rules.triggers.forEach(trigger => {
    if (data) {
      data += `&${triggersKey}[${trigger.id}][type]=${trigger.type}`
    } else {
      data += `${triggersKey}[${trigger.id}][type]=${trigger.type}`
    }
    switch (trigger.type) {
      case AutomationRuleTriggersEnum.blog_page_form_subscribed: {
        if (trigger.page.type) {
          const type =
            trigger.page.type === BlogPageEnum.blog_home ||
            trigger.page.type === BlogPageEnum.blog_static
              ? BlogEntityEnum.blog_page
              : trigger.page.type
          data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.blog}]=${trigger.blog.id}`
          data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.blog_entity}]=${type}`
          if (trigger.page.id) {
            data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.page}]=${trigger.page.id}`
          }
        }
        break
      }
      case AutomationRuleTriggersEnum.tag_removed:
      case AutomationRuleTriggersEnum.tag_added: {
        data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.tag}]=${trigger.tag.id}`
        break
      }
      case AutomationRuleTriggersEnum.form_subscribed:
      case AutomationRuleTriggersEnum.page_visited:
      case AutomationRuleTriggersEnum.new_sale:
      case AutomationRuleTriggersEnum.subscription_payment_failed:
      case AutomationRuleTriggersEnum.sale_canceled: {
        data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.funnel}]=${trigger.funnelStep.funnel.id}`
        data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.funnelStep}]=${trigger.funnelStep.id}`
        break
      }
      case AutomationRuleTriggersEnum.email_opened: {
        data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.mailing}]=${trigger.mailing.id}`
        break
      }
      case AutomationRuleTriggersEnum.email_link_clicked: {
        data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.mailing}]=${trigger.mailing.id}`
        if (trigger.link?.id) {
          data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.link}]=${trigger.link.id}`
        }
        break
      }
      case AutomationRuleTriggersEnum.enrolled_in_course:
      case AutomationRuleTriggersEnum.course_completed: {
        data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.course}]=${trigger.course.id}`
        break
      }
      case AutomationRuleTriggersEnum.module_completed: {
        data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.course}]=${trigger.course.id}`
        if (trigger.module?.id) {
          data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.module}]=${trigger.module.id}`
        }
        break
      }
      case AutomationRuleTriggersEnum.lecture_completed: {
        data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.course}]=${trigger.course.id}`
        if (trigger.module?.id) {
          data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.module}]=${trigger.module.id}`
        }
        if (trigger.lecture?.id) {
          data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.lecture}]=${trigger.lecture.id}`
        }
        break
      }
      case AutomationRuleTriggersEnum.enrolled_in_community: {
        // TODO remove ? after backend fix
        data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.community}]=${trigger.community?.id}`
        break
      }
      case AutomationRuleTriggersEnum.enrolled_in_course_bundle: {
        data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.courseBundle}]=${trigger.courseBundle.id}`
        break
      }
      case AutomationRuleTriggersEnum.campaign_completed: {
        data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.campaign}]=${trigger.campaign.id}`
        break
      }
      case AutomationRuleTriggersEnum.registered_to_webinar: {
        data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.funnel}]=${trigger.webinar.registrationFunnelStep.funnel.id}`
        data += `&${triggersKey}[${trigger.id}][${AutomationRuleNamesEnum.webinar}]=${trigger.webinar.id}`
        break
      }
    }
  })
  rules.actions.forEach(action => {
    if (data) {
      data += `&${actionsKey}[${action.id}][type]=${action.type}`
    } else {
      data += `${actionsKey}[${action.id}][type]=${action.type}`
    }
    switch (action.type) {
      case AutomationRuleActionsEnum.send_email: {
        data += `&${actionsKey}[${action.id}][${AutomationRuleNamesEnum.systemeEmail}]=${action.systemeEmail.id}`
        break
      }
      case AutomationRuleActionsEnum.add_tag:
      case AutomationRuleActionsEnum.remove_tag: {
        data += `&${actionsKey}[${action.id}][${AutomationRuleNamesEnum.tag}]=${action.tag.id}`
        break
      }
      case AutomationRuleActionsEnum.subscribe_to_campaign:
      case AutomationRuleActionsEnum.unsubscribe_from_campaign: {
        data += `&${actionsKey}[${action.id}][${AutomationRuleNamesEnum.campaign}]=${action.campaign.id}`
        break
      }
      case AutomationRuleActionsEnum.enroll_in_course:
      case AutomationRuleActionsEnum.revoke_access_to_course: {
        data += `&${actionsKey}[${action.id}][${AutomationRuleNamesEnum.course}]=${action.course.id}`
        if (action.type === AutomationRuleActionsEnum.enroll_in_course) {
          data += `&${actionsKey}[${action.id}][${AutomationRuleNamesEnum.courseAccessType}]=${action.accessType}`
        }
        break
      }
      case AutomationRuleActionsEnum.send_webhook: {
        data += `&${actionsKey}[${action.id}][${
          AutomationRuleNamesEnum.webhookUrl
        }]=${encodeURIComponent(action.webhookUrl)}`
        break
      }
      case AutomationRuleActionsEnum.create_user: {
        data += `&${actionsKey}[${action.id}][${AutomationRuleNamesEnum.locale}]=${action.locale}`
        break
      }
      case AutomationRuleActionsEnum.send_email_to_specific_address: {
        data += `&${actionsKey}[${action.id}][${AutomationRuleNamesEnum.emailMessageWithRecipient}]=${action.emailMessageWithRecipient.id}`
        break
      }
      case AutomationRuleActionsEnum.grant_access_to_community:
      case AutomationRuleActionsEnum.revoke_access_to_community: {
        // TODO remove ? after backend fix
        data += `&${actionsKey}[${action.id}][${AutomationRuleNamesEnum.community}]=${action.community?.id}`
        break
      }
      case AutomationRuleActionsEnum.enroll_in_course_bundle:
      case AutomationRuleActionsEnum.revoke_access_to_course_bundle: {
        data += `&${actionsKey}[${action.id}][${AutomationRuleNamesEnum.courseBundle}]=${action.courseBundle?.id}`
        if (action.type === AutomationRuleActionsEnum.enroll_in_course_bundle) {
          data += `&${actionsKey}[${action.id}][${AutomationRuleNamesEnum.enrollmentAccessType}]=${action.enrollmentAccessType}`
        }
        break
      }
    }
  })
  return data
}
