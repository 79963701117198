import EditActionWrapper from 'modules/automation-rules/actions/components/edit-actions/components/edit-action-wrapper'
import { EditActionItemInterface } from 'modules/automation-rules/actions/components/edit-actions/types/edit-action-item-interface'
import { ActionTagEditInterface } from 'modules/automation-rules/actions/types/automation-rule-actions-edit-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import OpenInNewIcon from 'shared/icons/open-in-new-icon'

interface ActionRemoveTagItemProps extends EditActionItemInterface<ActionTagEditInterface> {}

const ActionRemoveTagItem = ({
  actions,
  action,
  onDelete,
  setRules,
  errors,
  idx,
}: ActionRemoveTagItemProps) => {
  const { t } = useLocoTranslation()
  return (
    <EditActionWrapper slug={action.type} onDelete={() => onDelete(idx)}>
      {({ data, onOpen, renderActionLabel }) => (
        <FormSelect
          htmlFor={'none'}
          onOpen={onOpen}
          labelClassName={'w-full'}
          label={renderActionLabel({
            label: t('dashboard.tag.title'),
            additionalLabel: action.tag.id ? (
              <LinkWithoutPrefetch
                href={`/contacts?limit=10&filter=%5B%7B"searchValue"%3A"${action.tag.id}"%2C"compareOperation"%3A"IN_LIST"%2C"searchField"%3A"tag"%7D%5D`}
                rel="noreferrer"
                target={'_blank'}
                className={`outline-none group self-center`}
              >
                <OpenInNewIcon
                  className={'main-transition-colors group-focus-visible:fill-blue hover:fill-blue'}
                />
              </LinkWithoutPrefetch>
            ) : undefined,
          })}
          placeholder={t('dashboard.automation_rule.choose_tag')}
          value={action.tag.id}
          data={data.tagData}
          staticData={getDataFromObj(action.tag)}
          onChange={data =>
            setRules(prev => {
              return {
                ...prev,
                actions: actions.map((el, i) => {
                  if (idx === i) {
                    return { ...el, tag: { id: data } }
                  } else {
                    return el
                  }
                }),
              }
            })
          }
          error={errors.automationActions[action.id]?.tag?.join('')}
        />
      )}
    </EditActionWrapper>
  )
}
export default ActionRemoveTagItem
