import EditTriggerWrapper from 'modules/automation-rules/triggers/components/edit-triggers/components/edit-trigger-wrapper'
import { EditTriggerItemInterface } from 'modules/automation-rules/triggers/components/edit-triggers/types/edit-trigger-item-interface'
import { AutomationRuleTriggersDataEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { TriggersDataInterface } from 'modules/automation-rules/triggers/hooks/use-triggers-data'
import {
  TriggerEditType,
  TriggerFunnelEditInterface,
} from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import { getFunnelStepUrl } from 'modules/funnels/funnel/utils/get-funnel-step-url'
import { getFunnelUrl } from 'modules/funnels/utils/get-funnel-url'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import OpenInNewIcon from 'shared/icons/open-in-new-icon'

interface TriggerFunnelItemProps extends EditTriggerItemInterface<TriggerFunnelEditInterface> {
  funnelDataStr: AutomationRuleTriggersDataEnum
  funnelStepDataStr: AutomationRuleTriggersDataEnum
  funnelData: keyof Omit<TriggersDataInterface, 'blogEntityData'>
  funnelStepData: keyof Omit<TriggersDataInterface, 'blogEntityData'>
}

const TriggerFunnelItem = ({
  trigger,
  funnelStepDataStr,
  funnelDataStr,
  idx,
  onDelete,
  setRules,
  triggers,
  errors,
  funnelData,
  funnelStepData,
  isStatic,
}: TriggerFunnelItemProps) => {
  const { t } = useLocoTranslation()
  return (
    <EditTriggerWrapper trigger={trigger as TriggerEditType} onDelete={() => onDelete(idx)}>
      {({ renderTriggerLabel, data, onOpen, onClear }) => (
        <>
          <FormSelect
            disabled={isStatic}
            onOpen={() => onOpen(funnelDataStr)}
            htmlFor={'none'}
            label={renderTriggerLabel({
              label: t('dashboard.funnel.title'),
              additionalLabel: trigger.funnelStep.funnel.id ? (
                <LinkWithoutPrefetch
                  href={getFunnelUrl(trigger.funnelStep.funnel.id)}
                  rel="noreferrer"
                  target={'_blank'}
                  className={`outline-none group self-center`}
                >
                  <OpenInNewIcon
                    className={
                      'main-transition-colors group-focus-visible:fill-blue hover:fill-blue'
                    }
                  />
                </LinkWithoutPrefetch>
              ) : undefined,
            })}
            placeholder={t('dashboard.automation_rule.choose_funnel')}
            value={trigger.funnelStep.funnel.id}
            data={data[funnelData]}
            staticData={getDataFromObj(trigger.funnelStep.funnel)}
            onChange={data => {
              setRules(prev => {
                return {
                  ...prev,
                  triggers: triggers.map((el, i) => {
                    if (idx === i) {
                      return {
                        ...el,
                        funnelStep: { id: undefined, funnel: { id: data } },
                      }
                    } else {
                      return el
                    }
                  }),
                }
              })
              onClear(funnelStepDataStr)
            }}
            error={errors.automationTriggers[trigger.id]?.funnel?.join('')}
          />
          {trigger.funnelStep.funnel.id && (
            <FormSelect
              disabled={isStatic}
              onOpen={() => onOpen(funnelStepDataStr)}
              label={renderTriggerLabel({
                label: t('dashboard.funnel.funnel_step'),
                additionalLabel:
                  trigger.funnelStep.funnel.id && trigger.funnelStep.id ? (
                    <LinkWithoutPrefetch
                      href={getFunnelStepUrl(trigger.funnelStep.funnel.id, trigger.funnelStep.id)}
                      rel="noreferrer"
                      target={'_blank'}
                      className={`outline-none group self-center`}
                    >
                      <OpenInNewIcon
                        className={
                          'main-transition-colors group-focus-visible:fill-blue hover:fill-blue'
                        }
                      />
                    </LinkWithoutPrefetch>
                  ) : undefined,
              })}
              placeholder={t('dashboard.automation_rule.choose_funnel_step')}
              value={trigger.funnelStep.id}
              staticData={getDataFromObj({
                id: trigger.funnelStep.id,
                name: trigger.funnelStep.name,
              })}
              data={data[funnelStepData]}
              onChange={data =>
                setRules(prev => {
                  return {
                    ...prev,
                    triggers: triggers.map((el, i) => {
                      if (idx === i) {
                        return {
                          ...el,
                          funnelStep: {
                            id: data,
                            funnel: trigger.funnelStep.funnel,
                          },
                        }
                      } else {
                        return el
                      }
                    }),
                  }
                })
              }
              error={errors.automationTriggers[trigger.id]?.funnelStep?.join('')}
            />
          )}
        </>
      )}
    </EditTriggerWrapper>
  )
}

export default TriggerFunnelItem
