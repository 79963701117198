import { WrapperChildrenPropsInterface } from 'modules/automation-rules/actions/components/edit-actions/components/edit-action-wrapper'
import { ActionsDataInterface } from 'modules/automation-rules/actions/hooks/use-actions-data'
import { ActionTagEditInterface } from 'modules/automation-rules/actions/types/automation-rule-actions-edit-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import { createTag } from 'modules/tags/api/tags-api'
import { TagErrorsInterface } from 'modules/tags/types/tag-interface'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import FormSelect from 'shared/components/form-select'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { BadRequest } from 'shared/errors/bad-request'
import { InternalError } from 'shared/errors/internal-error'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import OpenInNewIcon from 'shared/icons/open-in-new-icon'

interface AddTagSelectorProps {
  onOpen: () => void
  action: {
    id: number
  } & ActionTagEditInterface
  onChange: (data: number) => void
  data: ActionsDataInterface
  error?: string
  renderActionLabel: WrapperChildrenPropsInterface['renderActionLabel']
}

function AddTagSelector({
  onOpen,
  action,
  error,
  onChange,
  data,
  renderActionLabel,
}: AddTagSelectorProps) {
  const { t } = useLocoTranslation()
  const [isCreateFetching, setIsCreateFetching] = useState(false)

  const handleCreateData = async (createData: { name: string }) => {
    try {
      const { data: newTag } = await createTag({ name: createData.name || '' })
      if (data.mutateTagsData) {
        await data.mutateTagsData(prev => {
          if (prev) {
            return [...prev, { id: newTag.id, name: newTag.name }]
          }
        })
      }
      onChange(newTag.id)
      setIsCreateFetching(false)
    } catch (e) {
      setIsCreateFetching(false)
      if (e instanceof BadRequest) {
        if (e.errors.common.length !== 0) {
          toast.error(e.errors.common.join(''))
        }
        if (e.errors.fields) {
          const errors = e.errors.fields as unknown as TagErrorsInterface
          toast.error(errors.name)
        }
      } else if (e instanceof InternalError) {
        toast.error(t('core.error.internal_error_message'))
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  const createData = {
    setCreateData: (data: { name: string }) => {
      setIsCreateFetching(true)
      handleCreateData(data)
    },
    getCustomCaption: (value: string) =>
      t('dashboard.automation_rule.create_tag_caption', { tagName: value }),
  }

  return (
    <FormSelect
      htmlFor={'none'}
      onOpen={onOpen}
      labelClassName={'w-full'}
      isCreating={isCreateFetching}
      label={renderActionLabel({
        label: t('dashboard.tag.title'),
        additionalLabel: action.tag.id ? (
          <LinkWithoutPrefetch
            href={`/contacts?limit=10&filter=%5B%7B"searchValue"%3A"${action.tag.id}"%2C"compareOperation"%3A"IN_LIST"%2C"searchField"%3A"tag"%7D%5D`}
            rel="noreferrer"
            target={'_blank'}
            className={`outline-none group self-center`}
          >
            <OpenInNewIcon
              className={'main-transition-colors group-focus-visible:fill-blue hover:fill-blue'}
            />
          </LinkWithoutPrefetch>
        ) : undefined,
      })}
      placeholder={t('dashboard.automation_rule.choose_create_tag')}
      value={action.tag.id}
      data={data.tagData}
      staticData={getDataFromObj(action.tag)}
      onChange={onChange}
      disabled={isCreateFetching}
      createData={createData}
      error={error}
    />
  )
}

export default AddTagSelector
