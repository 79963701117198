import ActionAddTagItem from 'modules/automation-rules/actions/components/edit-actions/components/action-add-tag-item'
import ActionCampaignItem from 'modules/automation-rules/actions/components/edit-actions/components/action-campaign-item'
import ActionCommunityItem from 'modules/automation-rules/actions/components/edit-actions/components/action-community-item'
import ActionCourseItem from 'modules/automation-rules/actions/components/edit-actions/components/action-course-item'
import ActionCreateUserItem from 'modules/automation-rules/actions/components/edit-actions/components/action-create-user-item'
import ActionEmailItem from 'modules/automation-rules/actions/components/edit-actions/components/action-email-item'
import ActionRemoveTagItem from 'modules/automation-rules/actions/components/edit-actions/components/action-remove-tag-item'
import ActionSpecEmailItem from 'modules/automation-rules/actions/components/edit-actions/components/action-spec-email-item'
import ActionWebhookItem from 'modules/automation-rules/actions/components/edit-actions/components/action-webhook-item'
import { EditActionItemInterface } from 'modules/automation-rules/actions/components/edit-actions/types/edit-action-item-interface'
import { AutomationRuleActionsEnum } from 'modules/automation-rules/actions/enums/action-enum'
import {
  ActionEditType,
  ActionsType,
} from 'modules/automation-rules/actions/types/automation-rule-actions-edit-interface'
import {
  AutomationRuleErrorInterface,
  AutomationRuleViewInterface,
} from 'modules/automation-rules/types/automation-rule-interface'
import React from 'react'
import ActionCourseBundleItem from './components/action-course-bundle-item'

export interface EditActionsProps {
  actions: ActionEditType[]
  setRules: React.Dispatch<React.SetStateAction<AutomationRuleViewInterface>>
  errors: AutomationRuleErrorInterface
}

const EditActions = ({ actions, setRules, errors }: EditActionsProps) => {
  const onDelete = (index: number) => {
    setRules(prev => {
      return { ...prev, actions: actions.filter((_, idx) => idx !== index) }
    })
  }

  return (
    <>
      {actions.map((action, idx) => {
        const baseProps: Omit<EditActionItemInterface<ActionsType>, 'action'> = {
          errors,
          setRules,
          onDelete,
          idx,
          actions,
        }

        switch (action.type) {
          case AutomationRuleActionsEnum.send_email: {
            return <ActionEmailItem key={idx} {...baseProps} action={action} />
          }
          case AutomationRuleActionsEnum.send_email_to_specific_address: {
            return <ActionSpecEmailItem key={idx} {...baseProps} action={action} />
          }

          case AutomationRuleActionsEnum.add_tag: {
            return <ActionAddTagItem key={idx} {...baseProps} action={action} />
          }
          case AutomationRuleActionsEnum.remove_tag: {
            return <ActionRemoveTagItem key={idx} {...baseProps} action={action} />
          }
          case AutomationRuleActionsEnum.send_webhook: {
            return <ActionWebhookItem key={idx} {...baseProps} action={action} />
          }
          case AutomationRuleActionsEnum.enroll_in_course:
          case AutomationRuleActionsEnum.revoke_access_to_course: {
            return <ActionCourseItem key={idx} {...baseProps} action={action} />
          }
          case AutomationRuleActionsEnum.subscribe_to_campaign:
          case AutomationRuleActionsEnum.unsubscribe_from_campaign: {
            return <ActionCampaignItem key={idx} {...baseProps} action={action} />
          }
          case AutomationRuleActionsEnum.create_user: {
            return <ActionCreateUserItem key={idx} {...baseProps} action={action} />
          }

          case AutomationRuleActionsEnum.grant_access_to_community:
          case AutomationRuleActionsEnum.revoke_access_to_community: {
            return <ActionCommunityItem key={idx} {...baseProps} action={action} />
          }

          case AutomationRuleActionsEnum.enroll_in_course_bundle:
          case AutomationRuleActionsEnum.revoke_access_to_course_bundle: {
            return <ActionCourseBundleItem key={idx} {...baseProps} action={action} />
          }

          default: {
            return null
          }
        }
      })}
    </>
  )
}

export default EditActions
