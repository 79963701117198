import { AutomationRuleActionsEnum } from 'modules/automation-rules/actions/enums/action-enum'
import { ActionEditType } from 'modules/automation-rules/actions/types/automation-rule-actions-edit-interface'

export const hasDefaultAction = (actions: ActionEditType[]) =>
  actions.some(action => {
    switch (action.type) {
      case AutomationRuleActionsEnum.add_tag:
      case AutomationRuleActionsEnum.remove_tag: {
        return !action.tag.id
      }
      case AutomationRuleActionsEnum.create_user: {
        return !action.locale
      }
      case AutomationRuleActionsEnum.send_email: {
        return !action.systemeEmail.id
      }
      case AutomationRuleActionsEnum.send_email_to_specific_address: {
        return !action.emailMessageWithRecipient.id
      }
      case AutomationRuleActionsEnum.enroll_in_course:
      case AutomationRuleActionsEnum.revoke_access_to_course: {
        return !action.course.id
      }
      case AutomationRuleActionsEnum.enroll_in_course_bundle:
      case AutomationRuleActionsEnum.revoke_access_to_course_bundle: {
        return !action.courseBundle?.id
      }
      case AutomationRuleActionsEnum.send_webhook: {
        return !action.webhookUrl
      }
      case AutomationRuleActionsEnum.subscribe_to_campaign:
      case AutomationRuleActionsEnum.unsubscribe_from_campaign: {
        return !action.campaign.id
      }
      case AutomationRuleActionsEnum.grant_access_to_community:
      case AutomationRuleActionsEnum.revoke_access_to_community: {
        // TODO remove ? after backend fix
        return !action.community?.id
      }
    }
  })
