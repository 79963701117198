import {
  actionDescriptionLocoKeys,
  actionTitleLocoKeys,
} from 'modules/automation-rules/actions/constants/action-loco-keys'
import { AutomationRuleActionsEnum } from 'modules/automation-rules/actions/enums/action-enum'
import {
  ActionsDataInterface,
  useActionsData,
} from 'modules/automation-rules/actions/hooks/use-actions-data'
import { getActionIcon } from 'modules/automation-rules/actions/utils/get-action-icon'
import React from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import CloseIcon from 'shared/icons/close-icon'

export interface WrapperChildrenPropsInterface {
  renderActionLabel: ({
    label,
    additionalLabel,
  }: {
    label: string
    additionalLabel?: JSX.Element
  }) => JSX.Element
  onOpen: () => void
  data: ActionsDataInterface
}
type ChildrenType = (args: WrapperChildrenPropsInterface) => JSX.Element

interface EditActionWrapperProps {
  slug: AutomationRuleActionsEnum
  children: ChildrenType
  onDelete: () => void
}

const EditActionWrapper = ({ slug, children, onDelete }: EditActionWrapperProps) => {
  const { t } = useLocoTranslation()

  const { data, setShouldFetch } = useActionsData()
  const renderActionLabel = ({
    label,
    additionalLabel,
  }: {
    label: string
    additionalLabel?: JSX.Element
  }) => (
    <div className={'flex justify-between gap-1.5'}>
      <div className="text-sm font-medium flex gap-0.5">
        <span>{label}</span>
        <span className="text-danger">*</span>
      </div>
      <div className={'flex gap-1.5 items-center'}>{additionalLabel}</div>
    </div>
  )

  const childrenData = {
    renderActionLabel,
    onOpen: () => setShouldFetch(prev => ({ ...prev, [slug]: true })),
    data,
  }

  return (
    <div className={`flex flex-col gap-3`}>
      <div className={'flex justify-between gap-3'}>
        <div className={'flex gap-3'}>
          <div className={'w-[40px] [&>svg]:w-[40px] [&>svg]:h-[40x]'}>{getActionIcon(slug)}</div>
          <div className={'flex gap-1 flex-col'}>
            <span className={'text-darkblue font-bold text-[15px]'}>
              {t(actionTitleLocoKeys[slug])}
            </span>
            <span>{t(actionDescriptionLocoKeys[slug])}</span>
          </div>
        </div>
        <button onClick={onDelete} className={`outline-none h-fit group`}>
          <CloseIcon
            className={
              'main-transition-colors fill-gray-100 group-hover:fill-darkblue group-focus-visible:fill-darkblue'
            }
          />
        </button>
      </div>
      {children(childrenData)}
    </div>
  )
}

export default EditActionWrapper
