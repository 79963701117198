import EditActionWrapper from 'modules/automation-rules/actions/components/edit-actions/components/edit-action-wrapper'
import { EditActionItemInterface } from 'modules/automation-rules/actions/components/edit-actions/types/edit-action-item-interface'
import { ActionCourseEditInterface } from 'modules/automation-rules/actions/types/automation-rule-actions-edit-interface'
import { AutomationRuleCourseAccessDataInterface } from 'modules/automation-rules/types/automation-rule-interface'
import { getDataFromObj } from 'modules/automation-rules/utils/get-filter-data'
import {
  AccessTypeCaptions,
  AccessTypeEnum,
} from 'modules/courses/courses/curriculum/enums/access-type-enum'
import { getCourseCurriculumUrl } from 'modules/courses/courses/curriculum/utils/get-course-curriculum-url'
import React from 'react'
import FormSelect from 'shared/components/form-select'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import RadioGroup from 'shared/components/radio-group'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import OpenInNewIcon from 'shared/icons/open-in-new-icon'
import { AutomationRuleActionsEnum } from '../../../enums/action-enum'

interface ActionCourseItemProps extends EditActionItemInterface<ActionCourseEditInterface> {}

const ActionCourseItem = ({
  actions,
  action,
  errors,
  setRules,
  onDelete,
  idx,
}: ActionCourseItemProps) => {
  const { t } = useLocoTranslation()

  const courseAccessTypeData: AutomationRuleCourseAccessDataInterface[] = [
    { id: AccessTypeEnum.fullAccess, caption: t(AccessTypeCaptions[AccessTypeEnum.fullAccess]) },
    {
      id: AccessTypeEnum.drippingContent,
      caption: t(AccessTypeCaptions[AccessTypeEnum.drippingContent]),
    },
  ]

  return (
    <EditActionWrapper slug={action.type} onDelete={() => onDelete(idx)}>
      {({ data, onOpen, renderActionLabel }) => (
        <>
          <FormSelect
            htmlFor={'none'}
            onOpen={onOpen}
            labelClassName={'w-full'}
            label={renderActionLabel({
              label: t('dashboard.courses.title'),
              additionalLabel: action.course.id ? (
                <LinkWithoutPrefetch
                  href={getCourseCurriculumUrl({ courseId: action.course.id })}
                  rel="noreferrer"
                  target={'_blank'}
                  className={`outline-none group self-center`}
                >
                  <OpenInNewIcon
                    className={
                      'main-transition-colors group-focus-visible:fill-blue hover:fill-blue'
                    }
                  />
                </LinkWithoutPrefetch>
              ) : undefined,
            })}
            placeholder={t('dashboard.automation_rule.choose_course')}
            value={action.course.id}
            data={data.courseData}
            staticData={getDataFromObj(action.course)}
            onChange={data =>
              setRules(prev => {
                return {
                  ...prev,
                  actions: actions.map((el, i) => {
                    if (idx === i) {
                      return { ...el, course: { id: data } }
                    } else {
                      return el
                    }
                  }),
                }
              })
            }
            error={errors.automationActions[action.id]?.course?.join('')}
          />
          {action.type === AutomationRuleActionsEnum.enroll_in_course && (
            <RadioGroup
              options={courseAccessTypeData}
              selected={action.accessType}
              onChange={data =>
                setRules(prev => {
                  return {
                    ...prev,
                    actions: actions.map((el, i) => {
                      if (idx === i) {
                        return { ...el, accessType: data }
                      } else {
                        return el
                      }
                    }),
                  }
                })
              }
            />
          )}
        </>
      )}
    </EditActionWrapper>
  )
}

export default ActionCourseItem
