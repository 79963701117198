import { AutomationRuleActionsEnum } from 'modules/automation-rules/actions/enums/action-enum'
import { ruleIconClassName } from 'modules/automation-rules/constants/automation-rule-icon-class'
import AddTagIcon from 'shared/icons/add-tag-icon'
import CampaignSubscribeIcon from 'shared/icons/campaign-subscribe-icon'
import CampaignUnsubscribeIcon from 'shared/icons/campaign-unsubscribe-icon'
import CommunityIcon from 'shared/icons/community-icon'
import CommunityUnenrollIcon from 'shared/icons/community-unenroll-icon'
import CourseBundleEnrollIcon from 'shared/icons/course-bundle-enroll-icon'
import CourseBundleUnenrollIcon from 'shared/icons/course-bundle-unenroll-icon'
import CourseIcon from 'shared/icons/course-icon'
import CourseUnenrollIcon from 'shared/icons/course-unenroll-icon'
import NewUserIcon from 'shared/icons/new-user-icon'
import RemoveTagIcon from 'shared/icons/remove-tag-icon'
import SendEmailIcon from 'shared/icons/send-email-icon'

export const getActionIcon = (action: AutomationRuleActionsEnum): JSX.Element => {
  switch (action) {
    case AutomationRuleActionsEnum.add_tag:
      return <AddTagIcon className={ruleIconClassName} />
    case AutomationRuleActionsEnum.remove_tag:
      return <RemoveTagIcon className={ruleIconClassName} />
    case AutomationRuleActionsEnum.create_user:
      return <NewUserIcon className={ruleIconClassName} />
    case AutomationRuleActionsEnum.enroll_in_course:
      return <CourseIcon className={`fill-gray-100 ${ruleIconClassName}`} />
    case AutomationRuleActionsEnum.revoke_access_to_course:
      return <CourseUnenrollIcon className={ruleIconClassName} />
    case AutomationRuleActionsEnum.revoke_access_to_course_bundle:
      return <CourseBundleUnenrollIcon className={ruleIconClassName} />
    case AutomationRuleActionsEnum.enroll_in_course_bundle:
      return <CourseBundleEnrollIcon className={ruleIconClassName} />
    case AutomationRuleActionsEnum.send_email:
    case AutomationRuleActionsEnum.send_email_to_specific_address:
    case AutomationRuleActionsEnum.send_webhook:
      return <SendEmailIcon />
    case AutomationRuleActionsEnum.subscribe_to_campaign:
      return <CampaignSubscribeIcon className={ruleIconClassName} />
    case AutomationRuleActionsEnum.unsubscribe_from_campaign:
      return <CampaignUnsubscribeIcon className={ruleIconClassName} />
    case AutomationRuleActionsEnum.grant_access_to_community:
      return <CommunityIcon className={ruleIconClassName} />
    case AutomationRuleActionsEnum.revoke_access_to_community:
      return <CommunityUnenrollIcon className={ruleIconClassName} />
  }
}
